<div class="row" style="padding-top: 20px"></div>

<div class="">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <!-- PASSES HEADER -->
      <div class="ibox-title">
        <h2 class="col-xs-11">
          <strong>Tessere</strong>
        </h2>
        <div class="ibox-tools col-xs-1 pull-right">
          <button *ngIf="isSoluzione1" type="file" class="btn btn-view btn-outline" title="Carica CSV tessere" style="margin-right: 4px;"
            [disabled]="isSoluzione1 && !this.passesForm?.value.idCompany" (click)="openUploadModal(uploadPassesCSVTemplate)" >
            <i class="fa fa-upload"></i>
          </button>
          <button *ngIf="isSoluzione1" type="button" (click)="newPass()" class="btn btn-view btn-outline">
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <!-- PASSES CONTENT -->
      <div class="ibox-content">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <form #passesForm="ngForm">
                <div *ngIf="isSoluzione1" class="row col-sm-12">
                  <div class="form-group col-sm-12 col-md-3">
                    <label for="idCompany">Azienda di trasporto</label>
                    <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" idCompany #idCompany="ngModel" (change)="onCompanySelect()">
                      <option [ngValue] = "'all'" > Tutte </option>
                      <option *ngFor="let o of companiesList" [ngValue]='o.idCompany'>{{o.legalBusinessName}}</option>
                    </select>
                  </div>
                </div>
                <ng-container *ngIf="showFilters">
                  <div class='col-sm-12 col-md-3'>
                    <label for="name">Nome</label>
                    <input class="form-control" type="text"
                    id="name"
                    name="name"
                    ngModel
                    #name="ngModel">
                  </div>
                  <div class='col-sm-12 col-md-3'>
                    <label for="code">Codice</label>
                    <input class="form-control" type="text"
                    id="code"
                    name="code"
                    ngModel
                    #code="ngModel">
                  </div>
                  <div class='col-sm-12 col-md-3'>
                    <label for="code">Descrizione</label>
                    <input class="form-control" type="text"
                    id="description"
                    name="description"
                    ngModel
                    #description="ngModel">
                  </div>
                  <div class='col-xs-6 col-sm-4 col-md-3 col-lg-2'>
                    <div class="form-group">
                      <label></label>
                      <div class='input-group col-md-12'>
                        <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline"> Cerca</button>
                        <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                          <i class="fa fa-rotate-left"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </form>
            </div>
          </div>
        </div>
        <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
          <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}
        </p>
        <div *ngIf="errorMessage === ''" class="table-responsive">
          <table class="table table-striped table-bordered table-hover dataTables-example">
            <thead>
              <tr>
                <th *ngIf="isSoluzione1">Azienda di Trasporto</th>
                <th>Nome</th>
                <th>Codice</th>
                <th>Descrizione</th>
                <th>Scadenza</th>
                <th class="text-center">Cliente associato</th>
                <th class="text-center">Abilitata</th>
                <th *ngIf="isSoluzione1"></th>
              </tr>
            </thead>
            <tbody *ngIf="errorMessage === ''">
              <tr *ngFor="let data of dataList" style="cursor:pointer">
                <td *ngIf="isSoluzione1" style="width: 200px;">{{ getCompanyName(data.idCompany) }}</td>
                <td>{{ data.name || '-' }}</td>
                <td>{{ data.code || '-' }}</td>
                <td>{{ data.description || '-' }}</td>
                <td>{{ (data.expireDate | date:'dd-MM-yyyy') || '-' }}</td>
                <td class="text-center"><i class="fa fa-check" *ngIf="data.customer"></i><i class="fa fa-times" *ngIf="!data.customer"></i></td>
                <td style="width: 50px"  class="text-center" >
                  <button class="{{ getButtonCircle(data.deleted) }}" type="button">
                </button>
                </td>
                <td *ngIf="isSoluzione1" style="width: 100px" class="col-md-4 text-center">
                  <button type="button" class="btn btn-success btn-outline" [disabled]="!data.customer" (click)="selectRow(data)">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button type="button" class="btn btn-danger btn-outline" style="margin-left: 3px;" (click)="deletePass(data.id)">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <ngb-pagination 
            *ngIf="visiblePaging"
            class="pull-right" 
            [collectionSize]="listService.totalRows"
            [maxSize]="10"
            [pageSize]="listService.rows" 
            [(page)]="listService.page"
            (pageChange)="pageChange($event)" >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #uploadPassesCSVTemplate></ng-template>