<div class="row" style="padding-top: 20px"></div>

<div class="">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <!-- VALIDATIONS HEADER -->
      <div class="ibox-title">
        <h2 class="col-xs-11">
          <strong>Validazioni</strong>
        </h2>
        <div class="ibox-tools col-xs-1 pull-right">
          <button *ngIf="isCompanyAdminstrator" type="button" class="btn btn-view btn-outline" [disabled]="isSoluzione1 && !this.validationsForm?.value.idCompany" (click)="openModal(validationModal)">
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <!-- VALIDATIONS CONTENT -->
      <div class="ibox-content">
        <form #validationsForm="ngForm">
          <div *ngIf="isSoluzione1" class="row">
            <div class="form-group col-sm-12 col-md-3">
              <label for="idCompany"> Azienda di trasporto </label>
              <select type="text" id="idCompany" class="form-control" ngModel name="idCompany" idCompany #idCompany="ngModel" (change)="onCompanySelect()">
                <option [ngValue] = "'all'" > Tutte </option>
                <option *ngFor="let o of companiesList" [ngValue]='o.idCompany'>{{o.legalBusinessName}}</option>
              </select>
            </div>
          </div>
          <ng-container class="filters" *ngIf="showFilters">
            <div class="row">
              <div class="form-group col-sm-12 col-md-3">
                <label for="email">Cliente</label>
                <input type="text" id="email" class="form-control" [(ngModel)]="dataFilter.email" name="email" email #email="ngModel">
              </div>
              <div class="form-group col-sm-12 col-md-3">
                <label for="codePass">Tessera</label>
                <input type="text" id="codePass" class="form-control" [(ngModel)]="dataFilter.codePass" name="codePass" codePass #codePass="ngModel">
              </div>
              <div class="form-group col-sm-12 col-md-3">
                <label for="idCustomerValidationTypology">Tipologia</label>
                <select type="text" id="idCustomerValidationTypology" class="form-control" [(ngModel)]="dataFilter.idCustomerValidationTypology" ngModel name="idCustomerValidationTypology" idCustomerValidationTypology #idCustomerValidationTypology="ngModel">
                  <option [ngValue]="'all'">Tutte</option>
                  <option *ngFor="let t of dataValidationTypologies" [ngValue]="t.id">
                    {{ t.typology }}
                  </option>
                </select>
              </div>
              <div class="form-group col-sm-12 col-md-3">
                <label for="status">Stato</label>
                <select type="text" id="status" class="form-control" [(ngModel)]="dataFilter.status" ngModel name="status" status #status="ngModel">
                  <option [ngValue]="'all'">Tutti</option>
                  <option *ngFor="let s of dataStatuses" [ngValue]="s.key">
                    {{ s.value }}
                  </option>
                </select>
              </div>
              <div class='col-xs-6 col-sm-4 col-md-3 col-lg-2'>
                <div class="form-group">
                  <label></label>
                  <div class='input-group col-md-12'>
                    <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline">Cerca</button>
                    <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                      <i class="fa fa-rotate-left"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </form>
        <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
          <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i> {{ errorMessage }}
        </p>
        <div *ngIf="errorMessage === '' && showFilters && !!dataList.length" class="table-responsive">
          <table class="table table-striped table-bordered table-hover dataTables-example">
            <thead>
              <tr>
                <th *ngIf="isSoluzione1">Azienda di Trasporto</th>
                <th>Cliente</th>
                <th>Tessera</th>
                <th>Tipologia</th>
                <th>Scadenza</th>
                <th>Info</th>
                <th>Note</th>
                <th>Stato</th>
                <th *ngIf="isCompanyAdminstrator"></th>
              </tr>
            </thead>
            <tbody *ngIf="errorMessage === ''">
              <ng-container *ngFor="let data of dataList; let i = index">
                <tr style="cursor:pointer">
                  <td *ngIf="isSoluzione1" style="width: 200px;">{{ getCompanyName(data.customer?.idCompany) || '-' }}</td>
                  <td>
                    {{ data.customer?.name || '-' }} {{ data.customer?.surname || '-' }}<br />
                    <small><b>Email:</b></small> {{ data.customer?.email || '-' }}
                  </td>
                  <td>
                    {{ data.codePass || '-' }}
                  </td>
                  <td>{{ data.typology?.typology || '-' }}</td>
                  <td>{{ (data.expireDate | date:'dd-MM-yyyy') || '-' }}</td>
                  <td class="text-nowrap">
                    <small>
                      <b>Richiesta per conto terzi</b>:
                      <i class="fa" [ngClass]="data.metadata ? 'fa-check' : 'fa-times'"></i>
                    </small><br />
                    <small *ngIf="data.metadata">
                      <b>Nome</b>:
                      {{ data.metadata?.firstName }} {{ data.metadata?.lastName }}<br />
                      <b>Codice fiscale</b>:
                      {{ data.metadata?.fiscalCode }}
                    </small>
                  </td>
                  <td>{{ data.description || '-' }}</td>
                  <td>{{ getStatus(data.status) || '-' }}</td>
                  <td *ngIf="isCompanyAdminstrator" style="width: 200px;" class="col-md-4 text-center">
                    <div class="validation-actions" style="display: flex; justify-content: space-between;">
                      <button type="button" title="Mostra lista file" class="btn btn-primary btn-outline" (click)="toggleDetails(data, i)">
                        <i class="fa fa-angle-double-down rotate-icon" [ngClass]="{'collpaseDetails' : !data.expanded }" aria-hidden="true"></i>
                      </button>
  
                      <div *ngIf="isCompanyAdminstrator">
                        <button *ngIf="!data.codePass" type="button" title="Associa tessera ed attiva" class="btn btn-success btn-outline" (click)="openModal(validationAssignModal, data)">
                          <i class="fa fa-id-card" style="font-size: 1.1rem;"></i>
                        </button>
                        <button type="button" title="Cambio stato validazione" class="btn btn-success btn-outline" style="margin-left: 3px;" (click)="openModal(statusChangeModal, data)">
                          <i class="fa fa-play"></i>
                        </button>
                        <button type="button" title="Elimina validazione" class="btn btn-danger btn-outline" style="margin-left: 3px;" (click)="deleteValidation(data.id)">
                          <i class="fa fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <!-- VALIDATION FILES -->
                <ng-container *ngIf="data.expanded && !loading">
                  <ng-container *ngIf="data.expanded && !!validationFiles.length; else noFiles">
                    <tr *ngFor="let file of validationFiles" class="detailRow">
                      <td colspan="4">{{file.name || '-'}}</td>
                      <td colspan="3">{{file.description || '-'}}</td>
                      <td colspan="1" class="text-right">
                        <button type="button" title="Scarica file" class="btn btn-success btn-outline" [disabled]="file.deleted" (click)="downloadFile(data, file.id)">
                          <i class="fa fa-download"></i>
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-template #noFiles>
                    <tr>
                      <td colspan="8" class="text-center">
                        Nessun file disponibile per la seguente validazione
                      </td>
                    </tr>
                  </ng-template>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
          <ngb-pagination 
            *ngIf="visiblePaging"
            class="pull-right" 
            [collectionSize]="listService.totalRows"
            [maxSize]="10"
            [pageSize]="listService.rows" 
            [(page)]="listService.page"
            (pageChange)="pageChange($event)" >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #validationModal>
  <div class="modal-dialog">
    <div class="modal-body">
      <div class="row">
        <h1 style="padding-left: 1rem">Richiesta nuova validazione</h1>
      </div>
      <div class="row">
        <p style="padding-left: 1rem">Inserisci l'email dell'utente per richiedere una nuova validazione</p>
      </div>
      <br />
      <div class="row">
        <form #newValidationForm="ngForm">
          <div class="form-group col-sm-12">
            <label for="email">Email<span style="color: red;">*</span></label>
            <input type="text" id="email" class="form-control" name="email" email #email="ngModel" ngModel required>
          </div>
        </form>
      </div>
      <br />
      <div class="row text-right" style="padding: 0 1.5rem;">
        <button type="button" class="btn btn-primary" [disabled]="!newValidationForm.valid" (click)="checkEmail(newValidationForm.controls.email.value)">Richiedi validazione</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #statusChangeModal>
  <div class="modal-dialog">
    <div class="modal-body">
      <div class="row">
        <h1 style="padding-left: 1rem">Cambio stato validazione</h1>
      </div>
      <div class="row">
        <p style="padding-left: 1rem">Seleziona un nuovo valore di stato da attribuire alla validazione</p>
      </div>
      <br />
      <div class="row">
        <form #statusChangeForm="ngForm">
          <div class="form-group col-sm-12">
            <label for="email">Stato<span style="color: red;">*</span></label>
            <select type="text" id="status" class="form-control" name="status" status #status="ngModel" [(ngModel)]="selectedValidation.status" ngModel required>
              <option *ngFor="let s of dataStatuses" [ngValue]="s.key">
                {{ s.value }}
              </option>
            </select>
          </div>
        </form>
      </div>
      <br />
      <div class="row text-right" style="padding: 0 1.5rem;">
        <button type="button" class="btn btn-primary" [disabled]="!statusChangeForm.valid" (click)="changeStatus(statusChangeForm.controls.status.value)">Cambia stato</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #validationAssignModal>
  <div class="modal-dialog">
    <div class="modal-body">
      <div class="row">
        <h1 style="padding-left: 1rem">Assegnazione tessera</h1>
      </div>
      <div class="row">
        <p style="padding-left: 1rem">Seleziona la tessera da assegnare alla validazione</p>
      </div>
      <br />
      <div class="row">
        <form #validationAssignForm="ngForm">
          <div class="form-group col-sm-12">
            <label for="email">Tessera<span style="color: red;">*</span></label>
            <select type="text" id="idPass" class="form-control" name="idPass" idPass #idPass="ngModel" ngModel required>
              <option *ngFor="let p of dataPasses" [ngValue]="p.id">
                {{ p.code }}
              </option>
            </select>
          </div>
        </form>
      </div>
      <br />
      <div class="row text-right" style="padding: 0 1.5rem;">
        <button type="button" class="btn btn-primary" [disabled]="!validationAssignForm.valid" (click)="assignPass(validationAssignForm.controls.idPass.value)">Cambia stato</button>
      </div>
    </div>
  </div>
</ng-template>
